// 241105
// Home1에 타이핑으로 들어오는 파트

import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "University of Seoul",
          "UOS's night",
          "서비스 1. F12",
          "서비스 2. 족보 공유",
          "서비스 제공: PAARAN",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
