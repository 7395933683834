import React, { useState } from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Particle from "../Particle";
import F12Login from "./F12Login";
import F12Card from "./F12Card";

function F12() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <Card
              style={{
                padding: "20px",
                boxShadow: "0px 4px 8px rgba(0,0,0,0.2)",
                backgroundColor: "transparent",
                border: "1px solid white",
                borderRadius: "8px",
              }}
            >
              <Card.Body>
                <h1
                  style={{
                    fontSize: "2.1em",
                    paddingBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  <strong className="purple">PORTAL</strong> 로그인
                </h1>
                <F12Login setIsLoggedIn={setIsLoggedIn} />
              </Card.Body>
            </Card>
          </Col>
          <Col
            md={5}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              <strong className="purple">F12</strong> 정보 수집 범위
            </h1>
            <F12Card />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default F12;
